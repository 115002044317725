
import { Options, Vue } from 'vue-class-component';
import SwiperSlides from '@/components/swiperSlides.vue';
import store from '@/store';
import { AuthClient } from '@/services/Services';
import { LoginServices } from '@/services/LoginServices';
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';

@Options({
    components: {
        SwiperSlides
    }
})
export default class Splash extends Vue {

    created(){
        if(store.state.user && store.state.user.identifier) {
            AuthClient.checkAndRefreshToken()
            .then(x => {
                LoginServices.loginCallback(x, this)
            })
            .catch(y => {
                LoginServices.logout();
            })
        } else {
            this.$router.replace(
                {
                    path: '/welcome',
                    query: this.$router.currentRoute.value.query
                }
            )
                    
        }
    }
}

